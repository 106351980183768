<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle
			cx="16"
			cy="16"
			r="13"
			fill="#1020F9"
			class="background"
		></circle>
		<path
			d="M16.0005 10C15.3633 10 14.8467 10.5166 14.8467 11.1538C14.8467 11.7911 15.3633 12.3077 16.0005 12.3077C16.6378 12.3077 17.1544 11.7911 17.1544 11.1538C17.1544 10.5166 16.6378 10 16.0005 10Z"
			fill="white"
		></path>
		<path
			d="M17.0001 21L17.0001 15C17.0001 14.4477 16.5524 14 16.0001 14C15.4478 14 15.0001 14.4477 15.0001 15L15.0001 21C15.0001 21.5522 15.4478 22 16.0001 22C16.5524 22 17.0001 21.5522 17.0001 21Z"
			fill="white"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconInfoCircleFilled",
});
</script>
